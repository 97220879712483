<template>
  <div class="feedback-view pa-10">
    <feedback-form />

    <contacts-panel />
  </div>
</template>

<script>
  import FeedbackForm from '@/views/FeedbackView/FeedbackForm'
  import ContactsPanel from '@/views/FeedbackView/ContactsPanel'

  export default {
    name: 'FeedbackView',
    components: { ContactsPanel, FeedbackForm }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .feedback-view {
    display: grid;
    grid-template-columns: 1fr 440px;
    gap: 40px;

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      grid-template-columns: initial;
    }
  }
</style>
