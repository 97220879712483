<template>
  <v-sheet class="contacts-panel px-10 py-5">
    <div class="d-flex align-center mb-12">
      <div class="contacts-panel__header font-weight-bold">Вопрос о работе РТС-тендер</div>
      <!--<v-spacer />-->
      <!--<router-link class="text-decoration-none font-weight-bold primary&#45;&#45;text" to="/faq">Частые вопросы</router-link>-->
    </div>

    <div v-for="organizationName of Organization" :key="organizationName" class="organization">
      <div class="text-uppercase">{{ organizations[organizationName].name }}</div>
      <div class="organization__contact-details mt-3">{{ organizations[organizationName].phone }}</div>
      <div class="organization__contact-details mt-2">{{ organizations[organizationName].email }}</div>

      <div class="mt-6 d-flex align-center">
        <div
          class="organization__status"
          :class="{ organization__status_off: !organizationStatuses[organizationName] }"
        />
        <div class="ml-4">{{ organizations[organizationName].formattedWorkingHours }}</div>
        <div class="ml-6">{{ organizations[organizationName].formattedWorkingDays }}</div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
  import _ from 'lodash'
  import { getMinutes, getHours, addDays, format, startOfWeek } from 'date-fns'
  import dateFnsRuLocale from 'date-fns/locale/ru'
  import { utcToZonedTime, format as formatZonedTime } from 'date-fns-tz'
  import { objectFromKeys } from 'geoportal/src/utils/func'

  const timeZone = 'Europe/Moscow'
  const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 })
  const formattedWorkingDays = _.range(0, 5)
    .map((i) => _.capitalize(format(addDays(firstDayOfWeek, i), 'EEEEEE', { locale: dateFnsRuLocale })))
    .join(' ')

  const Organization = Object.freeze({
    // VEB_RF: 'veb_rf',
    RTS_TENDER: 'rts_tender'
  })
  const organizations = {
    // [Organization.VEB_RF]: {
    //   name: 'ВЭБ.ДВ',
    //   phone: '8 (495) 540 47 37',
    //   workingStartHour: 8,
    //   workingEndHour: 18,
    //   formattedWorkingHours: null,
    //   workingDaysOfWeek: [1, 2, 3, 4, 5],
    //   formattedWorkingDays
    // },
    [Organization.RTS_TENDER]: {
      name: 'РТС-тендер Техподдержка',
      phone: '8 (499) 653 77 00',
      email: 'isupport@rts-tender.ru',
      workingStartHour: 5,
      workingEndHour: 19,
      formattedWorkingHours: null,
      workingDaysOfWeek: [1, 2, 3, 4, 5],
      formattedWorkingDays
    }
  }
  Object.values(organizations).forEach((organizationData) => {
    const { workingStartHour, workingEndHour } = organizationData
    organizationData.formattedWorkingHours = `${workingStartHour}:00 - ${workingEndHour}:00`
  })
  const organizationsOrder = [/* Organization.VEB_RF,*/ Organization.RTS_TENDER]

  export default {
    name: 'ContactsPanel',

    data() {
      return {
        Organization,
        organizationsOrder,
        organizations,
        organizationStatuses: objectFromKeys(Object.values(Organization), () => false)
      }
    },

    created() {
      this.updateCities()
      this.timerId = setInterval(this.updateCities, 60_000)
    },

    destroyed() {
      clearInterval(this.timerId)
    },

    methods: {
      updateCities() {
        const zonedCurrentTime = utcToZonedTime(new Date(), timeZone)
        const currentDayOfWeek = Number(formatZonedTime(zonedCurrentTime, 'i', { timeZone }))

        this.organizationStatuses = Object.values(Organization).reduce((acc, organizationName) => {
          const timeInHours = getMinutes(zonedCurrentTime) / 60 + getHours(zonedCurrentTime)
          const { workingStartHour, workingEndHour, workingDaysOfWeek } = organizations[organizationName]
          acc[organizationName] =
            timeInHours >= workingStartHour &&
            timeInHours <= workingEndHour &&
            workingDaysOfWeek.includes(currentDayOfWeek)
          return acc
        }, {})
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .contacts-panel {
    .contacts-panel__header {
      font-size: 26px;
    }

    .organization {
      & + .organization {
        margin-top: 60px;
      }

      .organization__contact-details {
        font-size: 24px;
        line-height: 28px;
      }

      .organization__status {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: map-get($theme-colors, 'success');
        box-shadow: 0 0 4px map-get($theme-colors, 'success');

        &.organization__status_off {
          background: map-get($theme-colors, 'error');
          box-shadow: 0 0 4px map-get($theme-colors, 'error');
        }
      }
    }
  }
</style>
