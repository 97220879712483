<template>
  <v-sheet class="feedback-form px-8 py-7 d-flex flex-column" :elevation="0">
    <div class="feedback-form__header font-weight-bold">Вопрос о работе Аквавосток</div>

    <base-form ref="form" v-model="formModel" class="mt-8 flex-grow-1" :configuration="formConfiguration">
      <div :id="CAPTCHA_TAG_ID" ref="captcha" class="h-captcha" />
    </base-form>

    <div v-if="isCaptchaErrorVisible" class="pl-3 text-xs error--text">Необходимо пройти проверку hCaptcha</div>

    <div>
      <v-btn
        class="mt-6"
        color="primary"
        :disabled="!isFormFilled"
        :loading="isPending"
        small
        depressed
        @click="sendFeedback()"
      >
        Отправить
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { VTextarea } from 'vuetify/lib'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { showMessage } from '@/utils/messages'
  import { emailRule, requiredPropertyRule } from '@/utils/forms'
  import { createFormConfiguration, formFieldPresets } from '@/components/BaseForm/formHelpers'

  const MESSAGE_MAX_LENGTH = 1000
  const formConfiguration = createFormConfiguration([
    [
      {
        id: 'mail_to_answer',
        label: 'Почта для получения ответа',
        required: true,
        bind: { type: 'email', rules: [requiredPropertyRule, emailRule], hideDetails: false }
      },
      formFieldPresets.text
    ],
    [
      {
        id: 'sender_name',
        label: 'Имя',
        required: true,
        bind: { rules: [requiredPropertyRule], hideDetails: false }
      },
      formFieldPresets.text
    ],
    [
      {
        id: 'sender_organization',
        label: 'Название организации',
        required: true,
        bind: { rules: [requiredPropertyRule], hideDetails: false }
      },
      formFieldPresets.text
    ],
    [
      {
        id: 'message',
        label: 'Ваше сообщение',
        cmp: VTextarea,
        required: true,
        bind: {
          rules: [
            requiredPropertyRule,
            (value) =>
              !value ||
              value.length <= MESSAGE_MAX_LENGTH ||
              `Длина сообщения не должна превышать ${MESSAGE_MAX_LENGTH} знаков`
          ],
          hideDetails: false,
          counter: MESSAGE_MAX_LENGTH,
          dense: true,
          filled: true
        }
      }
    ]
  ])

  const CAPTCHA_SCRIPT_TAG_ID = 'captcha-script'
  const CAPTCHA_TAG_ID = 'h-captcha'

  export default {
    name: 'FeedbackForm',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        formConfiguration,
        formModel: {},

        CAPTCHA_TAG_ID,
        captchaWidgetId: null,

        isCaptchaErrorVisible: false
      }
    },

    computed: {
      ...get({
        portalSettings: 'portalSettings'
      }),

      isFormFilled() {
        return Object.values(this.formModel).every(Boolean)
      }
    },

    mounted() {
      this.initCaptchaScript()
    },

    methods: {
      initCaptchaScript() {
        if (document.getElementById(CAPTCHA_SCRIPT_TAG_ID)) {
          this.renderCaptcha()
          return
        }

        // attach captcha script
        const $captchaScript = document.createElement('script')
        $captchaScript.setAttribute('id', CAPTCHA_SCRIPT_TAG_ID)
        $captchaScript.setAttribute('src', 'https://hcaptcha.com/1/api.js?render=explicit')
        $captchaScript.setAttribute('async', '')
        $captchaScript.setAttribute('defer', '')
        $captchaScript.addEventListener('load', () => this.renderCaptcha())
        document.head.append($captchaScript)
      },

      async renderCaptcha() {
        this.captchaWidgetId = window.hcaptcha.render(this.CAPTCHA_TAG_ID, {
          sitekey: this.portalSettings.portal.captcha.hcaptcha_sitekey,
          callback: () => {
            this.isCaptchaErrorVisible = false
          }
        })
      },

      @handlePendingTask()
      async sendFeedback() {
        if (!this.$refs.form.validate()) {
          return
        }

        const captchaKey = window.hcaptcha.getResponse(this.captchaWidgetId)
        if (!captchaKey) {
          this.isCaptchaErrorVisible = true
          return
        }

        this.isCaptchaErrorVisible = false
        const requestData = { ...this.formModel, client_response: captchaKey }

        await this.$api.backend.feedback.send_feedback(requestData)
        showMessage('Письмо успешно отправлено')

        this.formModel = {}
        this.$refs.form.$refs.form.resetValidation()
        window.hcaptcha.reset(this.captchaWidgetId)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .feedback-form {
    .feedback-form__header {
      font-size: 26px;
      line-height: 30px;
    }

    .base-form {
      grid-template-rows: min-content 1fr;
      gap: normal;

      &::v-deep {
        .v-input.v-textarea {
          flex-direction: column;
          flex-grow: 1;

          .v-input__slot {
            flex-grow: 1;
          }

          .v-text-field__details {
            flex-grow: 0;
          }
        }
      }
    }
  }
</style>
